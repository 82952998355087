<template>
  <b-card>
    <FormBuilder
      label="Event"
      create
      :fields="fields"
      :loading-submit="$store.state.event.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'featuredImage',
          label: 'Featured Image',
          type: 'image',
          required: true,
        },
        {
          key: 'thumbnailImage',
          label: 'Thumbnail Image',
          type: 'image',
          required: true,
        },
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'slug',
          label: 'Slug',
          required: true,
        },
        {
          key: 'type',
          label: 'Type',
          type: 'select',
          options: [
            { text: 'Sign up', value: 'sign_up' },
          ],
          required: true,
        },
        {
          key: 'sort',
          label: 'Sort',
          type: 'number',
          required: true,
        },
        {
          key: 'shortDescription',
          label: 'Short Description',
          type: 'textarea',
          required: true,
        },
        {
          key: 'longDescription',
          label: 'Long Description',
          type: 'tinymce',
          required: true,
        },
        {
          key: 'startDate',
          label: 'Start Date',
          type: 'datetime',
          required: true,
        },
        {
          key: 'endDate',
          label: 'End Date',
          type: 'datetime',
          required: true,
        },
        {
          key: 'status',
          label: 'Status',
          required: true,
          type: 'select',
          sortable: false,
          options: [
            { text: 'Waiting for Publication', value: 'waiting' },
            { text: 'Published', value: 'published' },
            { text: 'Completed', value: 'completed' },
            { text: 'Canceled', value: 'canceled' },
          ],
        },
        {
          key: 'cost',
          label: 'Cost',
          type: 'number',
          required: true,
        },
        {
          key: 'cta',
          label: 'CTA',
          required: true,
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('event/create', {
        ...data,
        roles: ['customer'],
      })
        .then(callback)
    },
  },
}
</script>
